<template>
  <v-autocomplete
    v-model="selectOption"
    :items="componentTypes"
    :loading="loading"
    :search-input.sync="search"
    class="filter-select text-sm"
    hide-details
    clearable
    hide-no-data
    item-text="name"
    item-value="id"
    label="Tipul componentelor"
    no-filter
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'FilterByComponentType',
  props: {
    filterName: {}
  },
  data () {
    return {
      paginatedComponentTypes: {
        data: []
      },
      selectOption: '',
      loading: false,
      search: ''
    }
  },
  computed: {
    componentTypes () {
      return this.paginatedComponentTypes.data
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    },
    selectOption (val) {
      if (typeof val === 'undefined') {
        this.$emit('filter', '', this.filterName)
      } else {
        this.$emit('filter', val, this.filterName)
      }
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      this.$http.get(`/product-component-types?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedComponentTypes = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
