<template>
  <div>
    <v-text-field
        v-model="searchValue"
        v-debounce:300="filter"
        :placeholder="placeholder"
        class="text-sm px-4 filter-input"
    >
      <v-icon
          v-if="!hideIcon"
          slot="append"
          color="red"
      >
        mdi-magnify
      </v-icon>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'FilterInput',
  props: {
    filterName: {
      required: true
    },
    placeholder: {
      default: 'Cauta...'
    },
    hideIcon: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      searchValue: '',
      isTyping: false
    }
  },
  methods: {
    filter () {
      this.$emit('filter', this.searchValue, this.filterName)
    }
  }
}
</script>

<style lang="scss">
</style>
